<template>
  <v-dialog
    v-if="!hideModal && open"
    :persistent="false"
    v-model="open"
    ref="dialog"
    transition="custom-dialog-transition"
  >
    <div @click="$emit('resetModal')" class="readme-overlay" />
    <v-card class="readme-card" :class="`readme-card--${itemStatus}`">
      <div class="readme-card__content">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-avatar v-if="pluginIcon" width="48px" height="48px" class="mr-2">
              <v-img width="48px" height="48px" :src="pluginIcon" />
            </v-avatar>
            <v-icon v-else color="primary" size="24" class="mr-4">
              $pluginIcon
            </v-icon>
            <h5 v-html="item.title || item.name" />
            <template v-if="!isNew">
              <div class="activated-chip" v-if="item.status === 'active'">
                {{ $t('message.status.active') }}
              </div>
              <div class="disabled-chip" v-else>{{ $t('message.status.disabled') }}</div>
            </template>
          </div>
          <v-icon
            size="24px"
            class="ml-4"
            @click="$emit('resetModal')"
            color="gray darken-2"
          >
            $close
          </v-icon>
        </div>

        <div
          class="mt-8 mb-6 p-1 base--text description"
          v-html="item.description || item.short_description"
        />

        <div class="d-flex align-center justify-space-between flex-wrap">
          <div class="d-flex align-center mt-2">
            <v-btn
              outlined
              color="gray"
              large
              elevation="0"
              @click="$emit('resetModal')"
            >
              <span class="heading--text">{{ $t('button.close') }}</span>
            </v-btn>
          </div>

          <div class="d-flex align-center mt-2">
            <v-btn
              v-if="isNew"
              color="primary"
              class="mr-0"
              large
              elevation="0"
              @click="emit('installItem', item)"
            >
              <v-icon size="24" color="white">$installIcon</v-icon>
              {{ $t('button.install') }}
            </v-btn>
            <template v-else>
              <v-btn
                v-if="item.status === 'inactive'"
                color="primary"
                large
                class="mr-4"
                elevation="0"
                @click="emit('action-button-activate', item)"
              >
                <v-icon size="24" color="white">$checkcircle</v-icon>
                {{ $t('button.activate') }}
              </v-btn>
              <v-btn
                v-if="item.update === 'available'"
                color="error"
                large
                class="mr-4"
                elevation="0"
                @click.native="emit('action-button-update', item)"
              >
                <v-icon size="24" color="white">$update</v-icon>
                {{ $t('button.update') }}
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </v-card>
    <div class="card-overlay" @click="open = !open" />
  </v-dialog>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      hideModal: false,
    };
  },
  methods: {
    emit: function (action) {
      this.hideModal = true;

      setTimeout(() => {
        this.$emit(action, this.item);
        this.$nextTick(() => {
          this.$emit("resetModal");
        });
        this.hideModal = false;
      }, 100);
    },
  },
  computed: {
    itemStatus: function () {
      if (this.item.status === "active") {
        if (this.item.update !== "available") {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "gray";
      }
    },
    pluginIcon: function () {
      if (this.item.icons && Object.keys(this.item.icons).length) {
        return this.item.icons.svg || this.item.icons["1x"];
      } else {
        return false;
      }
    },
    isNew: function () {
      return typeof this.rating == "number";
    },
    rating: function () {
      if (typeof this.item.rating !== "number") {
        return false;
      } else {
        return Math.ceil(this.item.rating / 2) / 10;
      }
    },
    open: {
      get: function () {
        if (Object.keys(this.item).length) {
          this.$store.dispatch("lockBodyScroll");

          return true;
        } else {
          this.$store.dispatch("unlockBodyScroll");

          return false;
        }
      },
      set: function (value) {
        if (!value) {
          this.$store.dispatch("unlockBodyScroll");
          setTimeout(() => {
            this.$emit("resetModal");
          }, 200);
        } else {
          this.$store.dispatch("lockBodyScroll");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  max-width: 720px !important;
  video {
    margin: 24px 0;
  }

  &.readme-card {
    max-width: 720px !important;
    // max-height: 80vh !important;
    // overflow-y: auto !important;
    // overflow-x: hidden !important;

    border-top: 6px solid;

    border-radius: 8px;
    border-color: var(--v-gray-lighten3);
    &--red {
      border-color: var(--v-error-base);
    }
    &--green {
      border-color: var(--v-primary-base);
    }
    .readme-card {
      &__content {
        padding: 32px;
      }
    }
  }
}

.description::v-deep {
  display: block;
  flex-direction: column;
  p {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    margin-bottom: 20px;
  }

  ul {
    list-style: disc;
  }

  blockquote {
    background-color: var(--v-gray-lighten5);
    margin: 20px 0;
    padding: 16px 16px 16px 80px;
    border-radius: 6px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 45px;
      height: 45px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NSA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iOCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iI0RFREVGMSIvPgo8cGF0aCBkPSJNMTcuNSAyMVYzNkMxNy41IDM3LjEwNDYgMTYuNjA0NiAzOCAxNS41IDM4SDgiIHN0cm9rZT0iI0RFREVGMSIgc3Ryb2tlLXdpZHRoPSI1Ii8+CjxyZWN0IHg9IjI1IiB5PSI4IiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHJ4PSIyIiBmaWxsPSIjREVERUYxIi8+CjxwYXRoIGQ9Ik00Mi41IDIxVjM2QzQyLjUgMzcuMTA0NiA0MS42MDQ2IDM4IDQwLjUgMzhIMzMiIHN0cm9rZT0iI0RFREVGMSIgc3Ryb2tlLXdpZHRoPSI1Ii8+Cjwvc3ZnPgo=");
      left: 16px;
      top: 16px;
    }
    * {
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: var(--v-gray-darken1);
      a {
        color: var(--v-primary-base);
      }
    }
  }

  ul,
  ol {
    margin-bottom: 20px;
    padding-left: 0px;
    > li {
      list-style-position: inside;

      font-size: $font-size-lg;
      line-height: $line-height-lg;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    font-size: $font-size-xlg;
    line-height: $line-height-xlg;
    font-style: normal;
    color: var(--v-text-darken2);
  }
  iframe {
    max-width: 100% !important;
    width: 100% !important;
    margin: 20px 0px;
  }
}

.v-avatar {
  border-color: var(--v-gray-lighten2) !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.activated-chip {
  padding: 0px 4px;
  border-radius: 5px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-bold;
  background-color: var(--v-primary-lighten4);
  color: var(--v-primary-base);
  margin-left: 16px;
  height: 24px;
  display: flex;
  align-items: center;
}
.disabled-chip {
  padding: 0px 4px;
  border-radius: 5px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-bold;
  background-color: var(--v-gray-lighten5);
  color: var(--v-gray-darken1);
  margin-left: 16px;
  height: 24px;
  display: flex;
  align-items: center;
}

.readme-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.v-application--is-rtl{
    .disabled-chip,
    .activated-chip{
        margin-left: 0px;
        margin-right: 16px;
    }
}
</style>
